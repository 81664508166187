import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query';
import { logout, setToken, setRefreshToken } from '../redux/slices/authSlice';
import { RootState } from '../redux/store';
import { settings } from '../settings';

const baseQuery = fetchBaseQuery({
    baseUrl: `${settings.url.host}${settings.url.api}`,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result: any = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.data.status === 401) {
        const { auth }: any = api.getState();
        const refreshResult: any = await baseQuery(
            {
                url: 'vedi/refresh-token',
                method: 'GET',
                params: { refreshToken: auth.refreshToken }
            },
            api,
            extraOptions
        );
        if (refreshResult?.data?.success) {
            const token = refreshResult.data?.data?.token;
            const refreshToken = refreshResult.data?.data?.refreshToken;
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);

            api.dispatch(setToken(token));
            api.dispatch(setRefreshToken(refreshToken));
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(logout());
        }
    }

    return result;
};
